import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import remarkBreaks from 'remark-breaks';

import {
  Box,
  Card,
  IconButton,
  InputBase,
  List,
  ListItem,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
// import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useTheme } from '@mui/material/styles';

import { styles } from '../styles/stylesheet';
import Chaas from '../pudina-chaas.png';
import { Message, Status } from '../types';
import { getChatThreadId } from '../utils/chat';
import Feedback from './Feedback';
import Loading from './Loading';
import { useApiClient } from '../context/ApiClientContext';

function ChatContainer({
  setIsChatOpen,
  siteId,
  status,
  messages,
  setMessages,
}: {
  setIsChatOpen: (val: boolean) => void;
  siteId: string;
  status: Status;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}) {
  // const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [input, setInput] = useState('');

  // State is to track when bot is responding
  const [loading, setLoading] = useState(false);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const { apiClient } = useApiClient();

  const theme = useTheme();

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSendMessage = async () => {
    const userInput = input.trim();
    if (userInput) {
      setMessages((prevMessages) => [
        ...prevMessages,
        // Setting ID to `-1` as id cannot be determined until response is received from server
        { text: userInput, isUser: true, messageId: '-1', feedback: null },
      ]);
      setInput('');
      setLoading(true);

      const chatThreadId = getChatThreadId();

      try {
        const response = await apiClient.post(
          `${process.env.REACT_APP_BACKEND_URL}/chats/${siteId}`,
          { chatThreadId, userInput },
          { headers: { 'Content-Type': 'application/json' } },
        );

        setMessages((prevMessages) => {
          // Update ID once response is received
          prevMessages[prevMessages.length - 1].messageId =
            response.data.messageId;

          return [
            ...prevMessages,
            {
              text: response.data.answer,
              isUser: false,
              messageId: response.data.messageId,
              feedback: null,
            },
          ];
        });
      } catch (error) {
        console.error('Error fetching bot response:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: 'Sorry, an error occurred. Please try again after sometime.',
            isUser: false,
            messageId: '-1',
            feedback: null,
          },
        ]);
      } finally {
        setLoading(false);
      }
    }
  };

  function renderMarkdown(text: string) {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkBreaks]}
        components={{
          code: ({ children, className, ref, ...rest }) => {
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <SyntaxHighlighter
                {...rest}
                PreTag='div'
                children={String(children).replace(/\n$/, '')}
                language={match[1]}
                style={vs2015}
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
          a: ({ href, children, ...rest }) => {
            return (
              <a
                href={href}
                target='_blank' // Open links in a new tab
                rel='noopener noreferrer' // For security reasons
                {...rest}
              >
                {children}
              </a>
            );
          },
        }}
      >
        {text}
      </ReactMarkdown>
    );
  }

  return (
    <>
      <Box
        sx={
          // isFullScreen ? styles.chatContainerFullScreen :
          styles.chatContainer
        }
      >
        <Box style={styles.header}>
          <img src={Chaas} alt='Chaas' style={styles.image} />
          <Typography
            variant='h6'
            style={{
              color: theme.palette.common.white,
              ...styles.chatbotTitle,
            }}
          >
            Chaas
          </Typography>
          {/* <IconButton
            sx={{
              color: '#fff',
            }}
            size='small'
            onClick={() => setIsFullScreen(!isFullScreen)}
          >
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton> */}
          <IconButton
            size='small'
            onClick={() => setIsChatOpen(false)}
            sx={{
              color: '#fff',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={styles.messagesArea}>
          {['indexing', 'pending', 'scraped', 'scraping'].includes(status) ? (
            <Box sx={styles.display}>
              <Typography
                sx={{
                  color: theme.palette.common.black,
                }}
              >
                The site is currently being{' '}
                {['indexing', 'scraped'].includes(status)
                  ? 'indexed'
                  : 'scraped'}
                , please check back later...
              </Typography>
            </Box>
          ) : status === 'loading' ? (
            <Box sx={styles.display}>
              <CircularProgress />
              <Typography color='black' variant='h6' sx={styles.loadingText}>
                Loading...
              </Typography>
            </Box>
          ) : (
            <List sx={{ padding: 0 }}>
              {messages.map((message, index) => (
                <ListItem
                  key={`${message.messageId}-${message.isUser ? 'user' : 'bot'}`}
                  sx={{
                    display: 'flex',
                    justifyContent: message.isUser ? 'flex-end' : 'flex-start',
                    mb: 1,
                  }}
                >
                  <Card
                    style={{
                      maxWidth:
                        // isFullScreen ? '60%' :
                        '70%',
                      backgroundColor: message.isUser ? '#C1E1C1' : '#ededed',
                      ...styles.messageCard,
                    }}
                  >
                    <Typography>
                      {message.isUser ? (
                        message.text
                      ) : (
                        <>
                          {renderMarkdown(message.text)}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Feedback message={message} />
                          </div>
                        </>
                      )}
                    </Typography>
                  </Card>
                </ListItem>
              ))}
              {loading && (
                <ListItem>
                  <Card sx={styles.ellipsisContainer}>
                    <Loading />
                  </Card>
                </ListItem>
              )}
              <div ref={messagesEndRef} />
            </List>
          )}
        </Box>

        {status === 'ready' && (
          <Box component={Paper} sx={styles.inputArea}>
            <InputBase
              fullWidth
              placeholder='Ask a question...'
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              sx={styles.input}
              multiline
              minRows={2}
              maxRows={5}
            />
            <IconButton
              onClick={handleSendMessage}
              disabled={
                messages.length > 0
                  ? messages[messages.length - 1].messageId === '-1'
                  : false
              }
            >
              <SendOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
}

export default ChatContainer;
