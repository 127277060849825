import React, { createContext, useContext, useEffect, useState } from 'react';

import { createClient, Session, SupabaseClient } from '@supabase/supabase-js';

import { useApiClient } from './ApiClientContext';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL!,
  process.env.REACT_APP_SUPABASE_ANON_KEY!,
);

export interface SessionContextValue {
  supabase: SupabaseClient;
  isUserAuthenticated: boolean;
  session: Session | null;
  setSession: (session: Session | null) => void;
  handleLogout: () => Promise<void>;
}

export const SessionContext = createContext<SessionContextValue>({
  supabase,
  isUserAuthenticated: false,
  session: null,
  setSession: () => {},
  handleLogout: async () => {},
});

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { apiClient } = useApiClient();
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [accessToken, setAccessToken] = useState<string | null>();
  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setIsLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setIsLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await apiClient.post(`${process.env.REACT_APP_BACKEND_URL}/users/logout`);
      setSession(null);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    setIsUserAuthenticated(false);

    if (!accessToken) {
      return;
    }

    const interceptor = apiClient.interceptors.request.use(
      (config) => {
        if (accessToken) {
          config.headers['Authorization'] = `${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    const checkAuthentication = async () => {
      try {
        await apiClient.get(`${process.env.REACT_APP_BACKEND_URL}/users`);

        setIsUserAuthenticated(true);
      } catch (error) {
        console.error('Authentication failed:', error);
      }
    };

    checkAuthentication();

    // Cleanup function to eject the interceptor when dependency changes
    return () => {
      apiClient.interceptors.request.eject(interceptor);
      setIsUserAuthenticated(false);
    };
  }, [accessToken, apiClient]);

  useEffect(() => {
    if (session) {
      setAccessToken(session.access_token);
    }
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
        supabase,
        isUserAuthenticated,
        handleLogout,
      }}
    >
      {!isLoading && children}
    </SessionContext.Provider>
  );
};

// Custom hook to use the SessionContext
export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};
